<template>
    <div class="comment">
		<div class="comment-title">评论</div>
        <div class="list" v-if="commentList.list&&commentList.list.length>0">
			<div class="comment-item" v-for="item in commentList.list" :key="item.id">
				<div class="content">{{item.comment}}</div>
				<div class="userInfo">
					<span><i class="el-icon-user"></i> {{item.createdUserName||'匿名用户'}}</span>
					<span style="margin-left:80px"><i class="el-icon-time"></i>{{item.createdTime}}</span>
					<span style="margin-left:80px;cursor:pointer" @click="deleteCommet(item.id)" v-if="id == item.createdUserId">删除</span>
				</div>
			</div>
			<div style="text-align:right;margin-top:8px">
				<el-pagination
					background
					layout="prev, pager, next"
					@current-change="hadlePage"
					:total="commentList.total">
				</el-pagination>
			</div>
		</div>
		<div v-else class="empty">
			暂无评论
		</div>
        <div class="comment-title">我来说两句</div>
        <div class="comment-form">
            <el-input
            v-model="comment"
            placeholder="我来说两句"
			maxlength="320"
            style="width: calc(100% - 140px)"
            ></el-input>
            <el-button type="warning" v-if="token" @click="submit" style="margin-left:20px">发布</el-button>
            <el-button type="warning" v-else @click="submit" style="margin-left:10px">登录并发布</el-button>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { commentAdd,commentGetPage ,deleteCommentApi} from '@/api/comment'
export default {
	// components:{dialogLogin},
	data(){
		return{
			comment:'',
			params:{
				currentPage:1,
				pageSize:10,
				filter:{
					objectId:''
				}
			},
			commentList:{list:[],total:0}
		}
	},
	props:{
		objectId:String,
		tableName:String,
	},
	computed:{
		...mapState({
			token:state=>state.token,
			id: state=>state.id,
		})
	},
	mounted(){
		this.params.filter.objectId = this.objectId
		this.getList();
	},
	watch:{
		token(a){
			if(a){
				this.submit();
			}
		}
	},
	methods:{
		submit(){
			if(!(this.comment&&this.comment.trim())){
				this.$message({
					message:'评论不可为空',
					type:'error'
				})
				return false
			}
			if(this.token){
				const data = {
					comment:this.comment,
					objectId:this.objectId,
					tableName:this.tableName,
				}
				commentAdd(data).then(res=>{
					if(res.data.code === 1){
						this.$message({
							message:'发布成功',
							type:'success'
						})
						this.comment = ''
						this.$emit('sum')
						this.getList()
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			}else{
				this.$bus.$emit('showLogin')
			}
		},
		deleteCommet(id){
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				deleteCommentApi(id).then(res=>{
					if(res.data.code == 1){
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					}else{
						this.$message({
							message:res.data.msg,
							type:'error'
						})
					}
				})
				
			}).catch(() => {         
			});
		},
		getList(){
			commentGetPage(this.params).then(res=>{
				this.commentList = res.data.data
			})
		},
		hadlePage(page){
			this.params.currentPage = page;
			this.getList()
		}
	}
}
</script>

<style scoped>
.empty{
	line-height: 80px;
	color:#ccc;
	text-align: center;
}
.comment-item{
	padding: 15px 0 5px 0;
	border-bottom: 1px solid #eee;
}
.content{
	color: #555;
	font-size: 16px;
	line-height: 24px;
}
.userInfo{
	font-size: 15px;
	color: #999;
	text-align: right;
	line-height: 30px;
}
.list{
	padding: 10px;
}
.comment-form {
  margin-top: 20px;
}
.comment-title {
  font-size: 18px;
  font-weight: 600;
}
.comment{
  margin-top: 20px;
  border-top: 2px solid #f89807;
  padding-top: 10px;
}
</style>