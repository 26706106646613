//面包屑导航对应路由
export const breadList = {
    '特色节庆':[
        {title:'乡宁荟',path:'/index'},
        {title:'乡宁游',path:'/index'},
        {title:'特色节庆列表',path:'/List?infoType=249251840589893&type=特色节庆'},
        {title:'特色节庆详情',path:''},
    ],
    '闲置资产统计':[
        {title:'资源集市',path:'/resource'},
        {title:'盘活资源',path:'/resource'},
        {title:'闲置资产统计',path:'/List?infoType=325317333450821&type=闲置资产统计'},
        {title:'闲置资产详情',path:''},
    ],
    '最新资讯':[
        {title:'最新资讯',path:''},
    ],
    '热门资讯':[
        {title:'热门资讯',path:''},
    ],
    '资讯':[
        {title:'首页',path:'/'},
        {title:'资讯详情',path:''},
    ],
    '相关资讯':[
        {title:'首页',path:'/'},
        {title:'相关资讯',path:''},
    ],
    '周末大讲堂':[
        {title:'三农学院',path:'/server'},
        {title:'三农课堂',path:'/school'},
        {title:'周末大讲堂',path:''},
    ],
    '三农知识库':[
        {title:'三农学院',path:'/server'},
        {title:'三农课堂',path:'/school'},
        {title:'三农知识库',path:''},
    ],
    '能人事迹':[
        {title:'乡宁情',path:'/talents'},
        {title:'能人事迹',path:'/talents'},
        {title:'能人事迹列表',path:'/story'},
        {title:'能人事迹详情',path:''},
    ],
    '招商项目':[
        {title:'乡宁旺',path:'/business'},
        {title:'招商项目',path:'/business'},
        {title:'招商项目列表',path:'/xnwzxList?infoType=257023606935621&type=3'},
        {title:'招商项目详情',path:''},
    ],
    '资金需求':[
        {title:'资源集市',path:'/resource'},
        {title:'企业资源',path:'/enterpriseResource'},
        {title:'资金需求列表',path:'/List?infoType=331379287793733&type=资金需求'},
        {title:'资金需求详情',path:''},
    ],
    '招商环境':[
        {title:'乡宁旺',path:'/business'},
        {title:'招商环境',path:'/business'},
        {title:'招商环境列表',path:'/List?infoType=257027635433541&type=招商环境'},
        {title:'招商环境详情',path:''},
    ],
    '招商动态':[
        {title:'乡宁旺',path:'/business'},
        {title:'招商动态',path:'/business'},
        {title:'招商动态列表',path:'/List?infoType=257023242199109&type=招商动态'},
        {title:'招商动态详情',path:''},
    ],
    '政务公开':[
        {title:'乡宁旺',path:'/business'},
        {title:'招商动态',path:'/business'},
        {title:'政务公开列表',path:'/List?infoType=455192684163141&type=政务公开'},//待更改id
        {title:'政务公开详情',path:''},
    ],
    '能人介绍':[
        {title:'乡宁情',path:'/talents'},
        {title:'能人介绍',path:'/talents'},
        {title:'能人列表',path:'/bestperson'},
        {title:'能人详情',path:''},
    ],
    '三农经验':[
        {title:'乡宁情',path:'/talents'},
        {title:'三农经验',path:'/talents'},
        {title:'三农经验列表',path:'/sxjyList'},
        {title:'三农经验详情',path:''},
    ],
    '综合信息':[
        {title:'三农学院',path:'/server'},
        {title:'三农服务',path:'/server'},
        {title:'综合信息列表',path:'/List?infoType=259033703653445&type=综合信息'},
        {title:'综合信息详情',path:''},
    ],
    '三农服务':[
        {title:'三农学院',path:'/server'},
        {title:'三农服务',path:'/server'},
        {title:'三农服务列表',path:'/List?infoType=249253253980229&type=三农服务'},
        {title:'三农服务详情',path:''},
    ],
    '农村新貌':[
        {title:'三农学院',path:'/server'},
        {title:'农村新貌列表',path:'/List?infoType=259034552643653&type=农村新貌'},
        {title:'农村新貌详情'}
    ],
    '农业天地':[
        {title:'三农学院',path:'/server'},
        {title:'三农服务',path:'/server'},
        {title:'农业天地列表',path:'/List?infoType=257025987149893&type=农业天地'},
        {title:'农业天地详情',path:''},
    ],
    '政策理论学习':[
        {title:'三农学院',path:'/server'},
        {title:'三农课堂',path:'/school'},
        {title:'政策理论学习列表',path:'/List?infoType=259035797561413&type=政策理论学习'},
        {title:'政策理论学习详情',path:''},
    ],
    '创客空间':[
        {title:'三农学院',path:'/server'},
        {title:'创客空间',path:'/creater'},
        {title:'创客空间列表',path:'/List?infoType=249253341319237&type=创客空间'},
        {title:'创客空间',path:''},
    ],
    '创客故事':[
        {title:'三农学院',path:'/server'},
        {title:'创客空间',path:'/creater'},
        {title:'创客故事列表',path:'/List?infoType=259036407320645&type=创客故事'},
        {title:'创客故事详情',path:''},
    ],
    '创客之星':[
        {title:'三农学院',path:'/server'},
        {title:'创客空间',path:'/creater'},
        {title:'创客之星列表',path:'/List?infoType=259036554174533&type=创客之星'},
        {title:'创客之星详情',path:''},
    ],
    '创客项目展':[
        {title:'三农学院',path:'/server'},
        {title:'创客空间',path:'/creater'},
        {title:'创客项目展',path:'/List?infoType=259037113167941&type=创客项目展'},
        {title:'创客项目展详情',path:''},
    ],
    '通知公告':[
        {title:'三农广播',path:'/dynamic'},
        {title:'三农动态',path:'/dynamic'},
        {title:'通知公告列表',path:'/List?infoType=249253697699909&type=通知公告'},
        {title:'通知公告库详情',path:''},
    ],
    '行业动态':[
        {title:'三农广播',path:'/dynamic'},
        {title:'行业动态',path:'/dynamic'},
        {title:'行业动态列表',path:'/List?infoType=249253844123717&type=行业动态'},
        {title:'行业动态详情',path:''},
    ],
    '价格监测':[
        {title:'三农广播',path:'/dynamic'},
        {title:'价格监测',path:'/dynamic'},
        {title:'价格监测列表',path:'/List?infoType=314985509285957&type=价格监测'},
        {title:'价格监测详情',path:''},
    ],
    '三农政策':[
        {title:'三农广播',path:'/dynamic'},
        {title:'政策红包',path:'/policy'},
        {title:'三农政策列表',path:'/List?infoType=249253465337925&type=三农政策'},
        {title:'三农政策详情',path:''},
    ],
    '补贴政策':[
        {title:'三农广播',path:'/dynamic'},
        {title:'政策红包',path:'/policy'},
        {title:'补贴政策列表',path:'/List?infoType=249253553061957&type=三农政策'},
        {title:'补贴政策详情',path:''},
    ],
    '补贴申请':[
        {title:'三农广播',path:'/dynamic'},
        {title:'政策红包',path:'/policy'},
        {title:'补贴申请列表',path:'/List?infoType=455192826572869&type=补贴申请'},//待更改id
        {title:'补贴申请详情',path:''},
    ],
    '责任落实':[
        {title:'脱贫攻坚',path:'/antipoverty'},
        {title:'责任落实',path:'/responsibility?page=zeren'},
        {title:'责任落实列表',path:'/List?infoType=589678048960581&type=责任落实'},
        {title:'责任落实详情',path:''},
    ],
    '防贫监测':[
        {title:'脱贫攻坚',path:'/antipoverty'},
        {title:'防贫监测',path:'/responsibility?page=fangpin'},
        {title:'防贫监测列表',path:'/List?infoType=589921984745541&type=防贫监测'},
        {title:'防贫监测详情',path:''},
    ],
    '务工就业':[
        {title:'脱贫攻坚',path:'/antipoverty'},
        {title:'务工就业',path:'/responsibility?page=wugong'},
        {title:'务工就业列表',path:'/List?infoType=589922335830085&type=务工就业'},
        {title:'务工就业详情',path:''},
    ],
    '项目资金':[
        {title:'脱贫攻坚',path:'/antipoverty'},
        {title:'项目资金',path:'/responsibility?page=zijin'},
        {title:'项目资金列表',path:'/List?infoType=589935358808133&type=项目资金'},
        {title:'项目资金详情',path:''},
    ],
}