<!--能人详情-->
<template>
  <div class="bestPerson">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁情</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/talents'}">招商引资</el-breadcrumb-item>
                <el-breadcrumb-item >招商动态详情</el-breadcrumb-item> -->
        <el-breadcrumb-item
          v-for="(item, k) in breadDatas[$route.query.type]"
          :key="k + 'bread'"
          :to="item.path"
          >{{ item.title }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="p-list w-1400">
      <el-row :gutter="30">
        <el-col :span="18">
          <div class="b-h2">
            {{ pageData.title }}
          </div>
          <div class="row sub-h2">
            <div>{{ pageData.releaseDate }}</div>
            <div>浏览：{{pageData.viewNumber||0}}</div>
            <div>评论：{{pageData.commentNumber||0}}</div>
          </div>
          <div
            v-if="!loading"
            class="b-content"
            v-html="pageData.content"
          ></div>
          <div v-else style="margin-top: 20px">
            <el-skeleton :rows="10" animated />
          </div>
          <div class="pageTo">
            <p @click="prev" :class="{ dis: prevDis }">
              上一篇：{{ prevRow.title || '已经是第一篇了' }}
            </p>
            <p @click="next" :class="{ dis: nextDis }">
              下一篇：{{ nextRow.title || '已经是最后一篇了' }}
            </p>
          </div>
          <!-- <comment :objectId="$route.query.id" :tableName="'t_s_information'" @sum="count"></comment> -->
          <!-- <div class="comment">
            <div class="comment-title">我来说两句</div>
            <div class="comment-form">
              <el-input
                v-model="comment"
                placeholder="我来说两句"
                style="width: calc(100% - 120px)"
              ></el-input>
              <el-button type="warning">发布</el-button>
            </div>
          </div> -->
        </el-col>
        <el-col :span="6">
          <div class="p-r">
            <div class="p-r-title row">
              <div
                :class="{ act: (params.filter.recommendTypes == ''|params.filter.recommendTypes == '推荐') }"
                @click="
                  params.filter.recommendTypes = '推荐'
                  getPageList()
                "
              >
                最新资讯
              </div>
              <div
                :class="{ act: params.filter.recommendTypes == '热门' }"
                @click="
                  params.filter.recommendTypes = '热门'
                  getPageList()
                "
              >
                热门资讯
              </div>
            </div>
            <div class="hot1 mt-20">
              <!-- <div class="hot1-title">西宁最新返乡政策</div> -->
            </div>
            <div
              class="hotlist clearfix"
              v-for="item in pageListData"
              :key="item.id + 'hoto'"
            >
               <router-link
                  :to="`/redirect2?path=/detail&type=${params.filter.recommendTypes?'热门':'推荐'}资讯&id=${item.id}&oParams=${JSON.stringify(params)}`"
                >
              <div class="hotlist-pic fl">
             
                  <el-image
                    style="width: 100%; height: 100%"
                    fit="cover"
                    :src="item.photoUrls"
                  ></el-image>
               
              </div>
              <div class="hot-text fl">
                <p>{{ item.title }}</p>
              </div>
               </router-link>
            </div>
          </div>
        </el-col>

      </el-row>
    </div>
  </div>
</template>
<script>
import thirdNav from '@/components/thirdNav'
import { GetDetail, GetTalents } from '@/api/xiangningqing'
import { GetZSDTinfos } from '@/api/xiangningwang'
import { breadList } from '@/utils/bread'
import comment from '@/components/comment'
export default {
  components: {
    thirdNav,
    comment
  },
  computed: {
    listParams: function () {
      return this.$route.query.oParams
        ? JSON.parse(this.$route.query.oParams)
        : {}
    },
  },
  data() {
    return {
      comment: '',
      pageData: {},
      breadDatas: breadList,
      listData: [],
      actIndex: 0,
      prevDis: false,
      nextDis: false,
      prevRow: {},
      nextRow: {},
      loading: false,
      pageListData: {},
      params: {
        currentPage: '1',
        pageSize: 5,
        filter: {
          recommendTypes: '',
        },
      },
    }
  },
  methods: {
    count(){this.$set(this.pageData,'commentNumber',this.pageData.commentNumber+1)},
    getDetail() {
      this.loading = true
      GetDetail(this.$route.query.id).then((res) => {
        console.log(res.data)
        this.pageData = res.data.data
        this.loading = false
      })
    },
    prev() {
      if (this.prevRow.id) {
        this.$router.push({
          path: '/redirect2',
          query: {
            path: this.$route.path,
            id: this.prevRow.id,
            type: this.$route.query.type,
            oParams: this.prevParams
              ? JSON.stringify(this.prevParams)
              : JSON.stringify(this.listParams),
          },
        })
      }
    },
    next() {
      if (this.nextRow.id) {
        this.$router.push({
          path: '/redirect2',
          query: {
            path: this.$route.path,
            id: this.nextRow.id,
            type: this.$route.query.type,
            oParams: this.nextParams
              ? JSON.stringify(this.nextParams)
              : JSON.stringify(this.listParams),
          },
        })
      }
    },

    getPageList() {
      GetTalents(this.params).then((res) => {
        this.pageListData = res.data.data.list
      })
    },
    getList() {
      GetZSDTinfos(this.listParams).then((res) => {
        this.actIndex =
          res.data.data.list.findIndex((v) => v.id == this.$route.query.id) || 0
        this.pageTotal = res.data.data.total
        this.listData = res.data.data.list
        this.prevRow = this.listData[this.actIndex - 1] || {}
        this.nextRow = this.listData[this.actIndex + 1] || {}
        if (this.actIndex == 0) {
          if (this.listParams.currentPage <= 1) {
            this.prevDis = true
          } else {
            this.prevParams = { ...this.listParams }
            this.prevParams.currentPage -= 1
            GetZSDTinfos(this.prevParams).then((res) => {
              this.prevRow = res.data.data.list[res.data.data.list.length - 1]
            })
          }
        }
        if (this.actIndex == this.listData.length - 1) {
          if (
            (this.listParams.currentPage - 1) * this.listParams.pageSize +
              this.actIndex +
              1 >=
            this.pageTotal
          ) {
            this.nextDis = true
          } else {
            this.nextParams = { ...this.listParams }
            this.nextParams.currentPage += 1
            GetZSDTinfos(this.nextParams).then((res) => {
              this.nextRow = res.data.data.list[0]
            })
          }
        }
      })
    },
  },
  mounted() {
    this.getDetail()
    if (this.$route.query.oParams) {
      this.getList()
    }
    this.getPageList()
  },
}
</script>
<style scoped>
.hot-text p {
  display: table-cell;
  vertical-align: middle;
}
.hot-text {
  width: 40%;
  height: 94px;
  padding: 10px;
  display: table;
  color: #666;
  line-height: 18px;
  font-size: 14px;
}
.hotlist-pic {
  width: 60%;
  height: 94px;
  overflow: hidden;
}
.hotlist {
  padding: 15px 0;
  border-top: 1px solid #ccc;
}
.hot1 {
  /* border-bottom: 1px solid #777; */
}
.hot1-title {
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
}
.p-r-title div:hover,
.p-r-title div.act {
  background: #f89807;
  color: #fff;
  cursor: pointer;
}
.p-r-title div {
  padding: 0 15px;
  margin: 0 5px;
}
.p-r {
  border: 1px solid #ccc;
  padding: 15px;
}
.p-r-title {
  font-size: 20px;
  line-height: 40px;
  justify-content: center;
}
.comment-form {
  margin-top: 20px;
}
.comment-title {
  font-size: 18px;
  font-weight: 600;
}
.comment {
  margin-top: 20px;
  border-top: 2px solid #f89807;
  padding-top: 10px;
}
.pageTo p:not(.dis):hover {
  color: #0e6ac1;
  text-decoration: underline;
  cursor: pointer;
}
.pageTo p.dis {
  color: #999;
}
.pageTo p {
  padding: 5px 0;
}
.pageTo {
  margin-top: 40px;
  font-size: 16px;
}
.picbox {
  margin: 15px 0;
}
.b-content p {
  text-indent: 30px;
}

.b-content {
  padding-top: 20px;
  width: 100%;
  overflow-x: auto;
}
.b-content >>> *{
  line-height: 28px;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: 'fangsong' !important;
  color: #666 !important;
}
.sub-h2 {
  font-size: 16px;
  color: #999;
  line-height: 30px;
  justify-content: space-between;
  border-bottom: 1px solid #777;
}
.b-h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
}
.bestPerson {
  font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: '微软雅黑';
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
