import axios from '@/utils/axios';
export function commentAdd(data) {
    /**
     * 新增评论
     **/
    return axios({
        method: 'post',
        url: "/api/XnsxInfo/Comment/Add",
        data,
    })
}
export function commentGetPage(data) {
    /**
     * 分页获取
     **/
    return axios({
        method: 'post',
        url: "/api/XnsxInfo/Comment/GetPage",
        data,
    })
}
export function deleteCommentApi(id) {
    /**
     * 删除
     **/
    return axios({
        method: 'delete',
        url: "/api/XnsxInfo/Comment/SoftDelete",
        params:{id},
    })
}
